<template>
  <div>
    <div v-if="graphDetailObject.memory_id">
      <AIChatInterface
      :assistantId="$store.state.eddie.mainAssistantId"
      :threadId="graphDetailObject.memory_id.thread_id"
      :vectorStoreId="graphDetailObject.memory_id.vector_store_id"
      />
    </div>
    <div v-else>
      No SmartGraph is Active
    </div>
  </div>
</template>

<script>
import AIChatInterface from './AIChatInterface.vue';

export default {
  components: {
    AIChatInterface,
  },
  computed: {
    isSmartGraph() {
      return Boolean(this.$store.state.gmodule.graph_detail.memory_id);
    },
    graphDetailObject() {
      // console.log('xvf', 'Check Clone Object', this.$store.gmodule.graph_detail, this.$store.getters['auth/authUser']);
      return this.$store.state.gmodule.graph_detail;
    },
    checkCreatorAccess() {
      const currentSelectedGraph = this.$store.state.current_thought;
      const currentUser = this.$store.getters['auth/authUser'];
      if (currentSelectedGraph && currentSelectedGraph.type === 'graph') {
        if (currentSelectedGraph.actor.username === currentUser.username) {
          return true;
        } return false;
      } return false;
    },
  },
  methods: {
    setCurrentThought(graphId, memoryId, graphName, vectorStoreId) {
      // console.log('xvf', 'setting current thought', graphId, memoryId, graphName);
      const graphObj = {
        graphId,
        memory_id: {
          thread_id: memoryId,
          vector_store_id: vectorStoreId,
        },
        label: graphName,
        type: 'graph',
      };

      this.$store.dispatch('setCurrentThought', { thoughtObject: graphObj });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
